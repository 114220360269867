<template>
  <v-container ma0 fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md5 lg4 xl3>
        <v-card class="pb-4">
          <v-card-title>
            <v-layout align-center justify-center row fill-height mt-3>
              <v-subheader class="headline">{{
                $t("changePassword.changePassword")
              }}</v-subheader>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-flex sm10 offset-sm1>
              <v-form ref="form">
                <v-text-field
                  v-model="password"
                  prepend-inner-icon="fas fa-lock fa-sm"
                  name="password"
                  :label="$t('changePassword.currentPassword')"
                  type="password"
                  :rules="passwordRules"
                  validate-on-blur
                  @keyup.enter="onConfirmClick"
                ></v-text-field>
                <v-text-field
                  id="newPassword"
                  v-model="newPassword"
                  prepend-inner-icon="fas fa-lock fa-sm"
                  name="newPassword"
                  :label="$t('changePassword.newPassword')"
                  type="password"
                  :rules="newPasswordRules"
                  validate-on-blur
                  @keyup.enter="onConfirmClick"
                ></v-text-field>
                <v-text-field
                  id="confirmNewPassword"
                  v-model="confirmNewPassword"
                  prepend-inner-icon="fas fa-lock fa-sm"
                  name="confirmNewPassword"
                  :label="$t('changePassword.confirmNewPassword')"
                  type="password"
                  :rules="confirmNewPasswordRules"
                  validate-on-blur
                  @keyup.enter="onConfirmClick"
                ></v-text-field>
              </v-form>
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="primary"
                  :loading="loading"
                  @click="onConfirmClick"
                  >{{ $t("global.confirm") }}</v-btn
                >
              </div>
              <v-alert
                :value="changePasswordAlert"
                color="success"
                icon="fas fa-check"
                outline
                class="mt-3"
                transition="scale-transition"
                >{{ message }}</v-alert
              >
            </v-flex>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import UsersService from "../services/UsersService.js";
export default {
  name: "ChangePasswordPage",
  data() {
    return {
      loading: false,
      password: null,
      newPassword: null,
      confirmNewPassword: null,
      message: ""
    };
  },
  computed: {
    changePasswordAlert() {
      return this.message.length > 0;
    },
    passwordRules() {
      return [
        v =>
          !!v ||
          this.$t("global.required", {
            value: this.$t("changePassword.currentPassword")
          })
      ];
    },
    newPasswordRules() {
      return [
        v =>
          !!v ||
          this.$t("global.required", {
            value: this.$t("changePassword.newPassword")
          })
      ];
    },
    confirmNewPasswordRules() {
      return [
        v =>
          !!v ||
          this.$t("global.required", {
            value: this.$t("changePassword.confirmNewPassword")
          }),
        v =>
          this.newPassword == v || this.$t("changePassword.passwordsMismatch")
      ];
    }
  },
  methods: {
    onConfirmClick() {
      if (this.$refs.form.validate()) {
        this.message = "";
        this.loading = true;
        UsersService.ChangeUserPassword(
          this.password,
          this.newPassword,
          this.confirmNewPassword
        ).then(response => {
          if (response.isValid) {
            this.message = this.$t("changePassword.passwordChanged");
            this.password = null;
            this.confirmNewPassword = null;
            this.newPassword = null;
          }
          this.loading = false;
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
